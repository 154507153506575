import { Button } from '@fabric-ds/react';

import bannerImg from '../../../public/banner-fhh.png';

import styles from './FrontpageBanner.module.css';

const bannerContent = {
    img: {
        src: bannerImg.src,
        alt: 'Et gult hus med hvite lister og norsk flagg på taket ved siden av et rødt hus med hvite lister omringet av trær og busker',
    },
    header: 'Sommerhytta er nærmere enn noen gang!',
    text: (
        <>
            Vi har pussa opp <b>Feriehjem og hytter skikkelig!</b> Nå er det mye enklere og tryggere å leie ut &mdash; eller leie sjæl.
        </>
    ),
    callToAction: 'Se nye fordeler',
    link: 'https://www.finn.no/reise/artikler/aktuelt/lei-ut-sommerhytta-enklere-enn-noen-gang',
};

export const FrontpageBanner = () => (
    <section
        className={`
                    flex flex-wrap flex-auto shadow-2 rounded-16 items-center justify-center 
                    md:flex-row-reverse md:flex-nowrap md:justify-between my-32
                `}>
        <div className={`w-full h-full overflow-hidden lg:rounded-lg ${styles.imgWrapper}`}>
            <img className={`object-cover object-left min-w-full ${styles.img}`} src={bannerContent.img.src} alt={bannerContent.img.alt} />
        </div>
        <div
            className={`
                        p-32 lg:p-40 gap-y-16 lg:gap-y-20 text-center md:text-left 
                        container flex flex-col flex-auto justify-center items-center md:items-start
                    `}>
            <div className="flex flex-col gap-y-8 md:gap-y-10">
                <h2>{bannerContent.header}</h2>
                <p>{bannerContent.text}</p>
            </div>
            <Button primary href={bannerContent.link} className="px-28 md:px-40">
                {bannerContent.callToAction}
            </Button>
        </div>
    </section>
);
