import { type MouseEvent, useState } from 'react';
import { TabPanel } from '@fabric-ds/react';

import { t } from '../locale/texts';
import { type Vertical, verticalConfig } from './common/config';

import { Menu } from '../page/Menu';
import { Heading } from './Heading';
import { FlightSearch } from './flight/FlightSearch';
import { HotelSearch } from './hotel/HotelSearch';
import { CarSearch } from './car/CarSearch';
import { PrePackageSearch } from './prepackage/PrePackageSearch';
import { FhhSearch } from './fhh/FhhSearch';

export type FrontProps = {
    vertical: Vertical;
    unleash?: Record<string, boolean>;
    widget?: string;
    pushUrlPath?: (vertical: Vertical, path: string) => void;
};

export const Front = ({ vertical, unleash, widget, pushUrlPath }: FrontProps) => {
    const [activeVertical, setActiveVertical] = useState(vertical);

    const setVertical = (event: MouseEvent<HTMLButtonElement>, clickedVertical: Vertical): void => {
        event.preventDefault();

        setActiveVertical(clickedVertical);

        if (pushUrlPath) {
            pushUrlPath(clickedVertical, verticalConfig[clickedVertical].fullPath);
        }
    };

    const renderVertical = () => {
        switch (activeVertical) {
            case verticalConfig.flight.vertical:
                return <FlightSearch widget={widget} position="travel frontpage" />;
            case verticalConfig.hotel.vertical:
                return <HotelSearch widget={widget} position="travel frontpage" />;
            case verticalConfig.carrental.vertical:
                return <CarSearch unleash={unleash} widget={widget} position="travel frontpage" />;
            case verticalConfig.prepackage.vertical:
                return <PrePackageSearch widget={widget} position="travel frontpage" />;
            case verticalConfig.fhh.vertical:
                return <FhhSearch widget={widget} position="travel frontpage" />;
            default:
                return null;
        }
    };

    return (
        <div data-testid="search-front">
            {widget ? (
                <h1 className="m-32 mt-0 text-center text-22" data-testid="widgetHeader">
                    {t(`widget.title.${activeVertical}`)}
                </h1>
            ) : (
                <h1 className="absolute sr-only mt-16 pl-16" data-testid="travelHeader">
                    {t('travel')}
                </h1>
            )}
            <Menu activeVertical={activeVertical} setVertical={setVertical} unleash={unleash} widget={widget} />
            {!widget && <Heading vertical={activeVertical} as="h2" />}
            <TabPanel name={activeVertical}>{renderVertical()}</TabPanel>
        </div>
    );
};
