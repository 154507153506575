import { type FormEvent, useEffect, useReducer, useState } from 'react';
import getConfig from 'next/config';
import { debounce } from 'ts-debounce';
import { DatePickerCalendar, DatePickerInput, DatePickerPopover, DateRangePicker } from '@finn-no/legacy-fabric-datepicker';
import { Select, TextField, Toggle } from '@fabric-ds/react';

import { t } from '../../locale/texts';
import { isMobile } from '../../util/mobile';
import { isNotWithinOneYearOfToday } from '../../util/datetime-fns';
import { getLastSearchPrepackage, storeLastSearchPrepackage } from '../common/lastSearch';
import { ppAutocompleteMapper } from '../common/autocompleteMapper';
import { trackSearchClick } from '../common/tracking';
import { buildParams } from './buildParams';

import { ppDefaultState } from './prePackageDefaultState';
import actions, { reducer } from './prePackageActions';

import { Autocomplete, type AutocompleteOption, type AutocompleteResponse } from '../common/Autocomplete';
import { DepartureByMonth } from './DepartureByMonth';
import { PrepackageSearchSubmit } from './PrepackageSearchSubmit';

import styles from './PrePackageSearch.module.css';

type PrePackageSearchProps = {
    // biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
    prefill?: Record<string, any>;
    widget?: string;
    position: string;
};

const {
    publicRuntimeConfig: { ppAutocompleteUrl, ppFacetUrl, ppResultUrl },
} = getConfig();

export const PrePackageSearch = ({ prefill, widget, position }: PrePackageSearchProps) => {
    const [pristine, setPristine] = useState(true);
    const [state, dispatch] = useReducer(reducer, ppDefaultState);

    useEffect(() => {
        dispatch(actions.allFieldsChanged({ ...getLastSearchPrepackage(), ...prefill }));
    }, []);

    const getFacets = (): void => {
        const params = buildParams(state);
        dispatch(actions.getFacetsStarted(params));

        fetch(`${ppFacetUrl}?${params}`, { method: 'GET' })
            .then((response) => response.json())
            .then((data) => dispatch(actions.getFacetsSuccess({ params, data })))
            .catch(() => dispatch(actions.getFacetsFailure(params)));
    };
    const formatMonths = (travelMonths): string | null =>
        travelMonths && travelMonths.length !== 0
            ? travelMonths.map((month) => `${month.year}-${`0${month.month}`.slice(-2)}`).join(', ')
            : null;
    const debouncedGetFacets = debounce(getFacets, 350);

    useEffect(() => {
        debouncedGetFacets();
    }, [
        state.searchType,
        state.originAirport,
        state.destinationId,
        state.departureDate,
        state.returnDate,
        state.flexibleDeparture,
        state.duration,
        state.travelMonths,
    ]);

    const validateState = (): boolean => {
        const validDestination = !state.destination || state.destination.trim() === '' || state.destinationId;
        const validFixedDeparture = state.searchType === 'fixed' && state.departureDate && state.returnDate;
        const validFlexibleDeparture = state.searchType === 'flexible';
        return validDestination && (validFixedDeparture || validFlexibleDeparture);
    };
    const invalid = {
        destination: !pristine && state.destination && !state.destinationId,
        departureDate: !pristine && state.searchType !== 'flexible' && !state.departureDate,
        returnDate: !pristine && state.searchType !== 'flexible' && !state.returnDate,
    };

    const facets = state.facetData?.[buildParams(state)];
    const lookupCount = (id): number => (facets ? facets[id] || 0 : 0);

    const autocompleteMapper = (response: AutocompleteResponse): AutocompleteOption[] =>
        ppAutocompleteMapper(response)
            .map((suggestion: AutocompleteOption): AutocompleteOption => ({ ...suggestion, count: lookupCount(suggestion.id) }))
            .sort((a, b) => (a.count && b.count ? b.count - a.count : 0))
            .slice(0, 8);
    const noOffersForFilters = state.destinationId && facets && lookupCount(state.destinationId) === 0;

    const originOptions = [
        { value: '', text: 'Alle flyplasser i Norge' },
        { value: 'OSL', text: 'Oslo, Gardermoen' },
        { value: 'TRF', text: 'Sandefjord, Torp' },
        { value: 'KRS', text: 'Kristiansand, Kjevik' },
        { value: 'SVG', text: 'Stavanger, Sola' },
        { value: 'HAU', text: 'Haugesund, Karmøy' },
        { value: 'BGO', text: 'Bergen, Flesland' },
        { value: 'AES', text: 'Ålesund, Vigra' },
        { value: 'MOL', text: 'Molde, Årø' },
        { value: 'KSU', text: 'Kristiansund, Kvernberget' },
        { value: 'TRD', text: 'Trondheim, Værnes' },
        { value: 'BOO', text: 'Bodø' },
        { value: 'EVE', text: 'Harstad/Narvik, Evenes' },
        { value: 'TOS', text: 'Tromsø, Langnes' },
        { value: 'ALF', text: 'Alta' },
        { value: 'GOT', text: 'Gøteborg, Landvetter' },
    ];

    const dateOptions = [
        { label: t('prepackage.searchType.fixed'), value: 'fixed' },
        { label: t('prepackage.searchType.flexible'), value: 'flexible' },
    ];

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        setPristine(false);

        if (validateState()) {
            const destination: string | undefined = state.destinationId?.split(';');
            const item = {
                id: 'finntravel',
                type: 'Travel',
                destination: destination?.[1],
                arrivalCity: destination?.[2],
                departureCountry: 'Norge',
                departureCity: originOptions?.find((option) => option.value === state.originAirport)?.text,
                travelType: 'prepackage',
                departureDate: (state.searchType === 'fixed' ? state.departureDate : formatMonths(state.travelMonths)) || undefined,
                returnDate: (state.searchType === 'fixed' ? state.returnDate : formatMonths(state.travelMonths)) || undefined,
            };
            trackSearchClick('Search prepackage', '/reise/pakkereiser', 'prepackage', position, item);
            storeLastSearchPrepackage({ ...state, facetData: undefined });
        } else {
            event.preventDefault();
        }
    };

    const numberOfMonths = isMobile() ? 1 : 2;

    return (
        <section className="bg-aqua-50 rounded-8 p-16" data-testid="prepackageSearch" aria-label={t('prepackage.title')}>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-16">
                <Select
                    id="originAirport"
                    data-testid="originAirport"
                    label={t('prepackage.origin.label')}
                    aria-label={t('prepackage.origin.label')}
                    value={state.originAirport}
                    onChange={(e) => dispatch(actions.setOriginAirport(e.target.value))}>
                    {originOptions.map((item) => (
                        <option key={`originAirport${item.value}`} value={item.value}>
                            {item.text}
                        </option>
                    ))}
                </Select>
                <Autocomplete
                    id="destination"
                    label={t('prepackage.destination.label')}
                    placeholder={t('prepackage.destination.placeholder')}
                    defaultValue={state.destination}
                    url={ppAutocompleteUrl}
                    minChars={2}
                    mapOptions={autocompleteMapper}
                    error={invalid.destination ? t('prepackage.validation.destination') : undefined}
                    onSelect={(option) => dispatch(actions.setDestination(option))}
                />
                <div id="departure-container">
                    <h3 className="sr-only">{t('prepackage.searchType.title')}</h3>
                    {state.searchType === 'flexible' ? (
                        <DepartureByMonth travelMonths={state.travelMonths} duration={state.duration} dispatch={dispatch} />
                    ) : (
                        <DateRangePicker
                            onChange={(newDates) => dispatch(actions.setDates(newDates))}
                            startDate={state.departureDate}
                            endDate={state.returnDate}
                            isDayDisabled={isNotWithinOneYearOfToday}>
                            <div className="grid grid-cols-2 gap-x-16">
                                <DatePickerInput
                                    as={TextField}
                                    dateField="startDate"
                                    id="date-range-start"
                                    label={t('prepackage.departureLabel')}
                                    placeholder={t('prepackage.departurePlaceholder')}
                                    error={invalid.departureDate}
                                    helpText={invalid.departureDate ? t('prepackage.validation.fixedDeparture') : undefined}
                                />
                                <DatePickerInput
                                    as={TextField}
                                    dateField="endDate"
                                    id="date-range-end"
                                    label={t('prepackage.returnLabel')}
                                    placeholder={t('prepackage.returnPlaceholder')}
                                    error={invalid.returnDate}
                                    helpText={invalid.returnDate ? t('prepackage.validation.fixedDeparture') : undefined}
                                />
                            </div>
                            <DatePickerPopover className="date-popover">
                                <DatePickerCalendar numberOfMonths={numberOfMonths} />
                                <div className="input input--select p-16 md:w-1/3">
                                    <label htmlFor="flexible-departure">{t('prepackage.flexible.departure')}</label>
                                    <div className="input--select__wrap">
                                        <select
                                            data-testid="flexible-departure"
                                            aria-label={t('prepackage.flexible.departure')}
                                            value={state.flexibleDeparture}
                                            onChange={(e) => dispatch(actions.setFlexibleDeparture(e.target.value))}>
                                            {[
                                                { value: 0, text: 'Ingen fleksibilitet' },
                                                { value: 1, text: '±1 dag' },
                                                { value: 2, text: '±2 dager' },
                                                { value: 3, text: '±3 dager' },
                                                { value: 7, text: '±1 uke' },
                                                { value: 14, text: '±2 uker' },
                                                { value: 21, text: '±3 uker' },
                                            ].map((item) => (
                                                <option key={`flexible-departure${item.value}`} value={item.value}>
                                                    {item.text}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </DatePickerPopover>
                        </DateRangePicker>
                    )}
                    <Toggle
                        type="radio"
                        options={dateOptions}
                        selected={dateOptions.filter((opt) => opt.value === state.searchType)}
                        onChange={(selected) => dispatch(actions.setSearchType(selected.value))}
                        className={`grid grid-cols-2 gap-x-16 mt-8 ${styles.ppDateToggles}`}
                    />
                </div>
            </div>
            {noOffersForFilters && (
                <div id="noOffersWarning" data-testid="noOffersWarning" className="text-red-600 mt-16">
                    {t('prepackage.validation.noOffers')}
                </div>
            )}
            <PrepackageSearchSubmit {...state} handleSubmit={handleSubmit} widget={widget} resultUrl={ppResultUrl} />
        </section>
    );
};
