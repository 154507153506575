import { useEffect, useReducer } from 'react';

import { getLastSearchHotel } from '../common/lastSearch';

import actions, { reducer } from './hotelActions';
import { hotelDefaultState } from './hotelDefaultState';

import { HotelSearchForm, type HotelSearchFormProps } from './HotelSearchForm';

type HotelSearchPrefill = Pick<HotelSearchFormProps, 'destination' | 'destinationContext' | 'startDate' | 'endDate' | 'rooms'>;

type HotelSearchProps = {
    prefill?: HotelSearchPrefill;
    tracking?: string;
    widget?: string;
    position: string;
};

export const HotelSearch = ({ prefill, tracking, widget, position }: HotelSearchProps) => {
    const [state, dispatch] = useReducer(reducer, { ...hotelDefaultState, ...prefill });

    useEffect(() => {
        if (!prefill) {
            dispatch(actions.allFieldsChanged(getLastSearchHotel()));
        }
    }, []);

    return <HotelSearchForm {...state} tracking={tracking} widget={widget} dispatch={dispatch} position={position} />;
};
