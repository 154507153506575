import { type FormEvent, useState } from 'react';
import getConfig from 'next/config';
import { DatePickerCalendar, DatePickerInput, DatePickerPopover, DateRangePicker } from '@finn-no/legacy-fabric-datepicker';
import { TextField } from '@fabric-ds/react';

import { t } from '../../locale/texts';
import { isMobile } from '../../util/mobile';
import { differenceInCalendarDays, isNotWithinOneYearOfToday } from '../../util/datetime-fns';
import { hotelAutocompleteMapper } from '../common/autocompleteMapper';
import { storeLastSearchHotel } from '../common/lastSearch';
import { trackSearchClick } from '../common/tracking';

import actions from './hotelActions';

import { Autocomplete } from '../common/Autocomplete';
import { Dropdown } from '../common/Dropdown';
import { type HotelRoom, HotelRooms } from './HotelRooms';
import { HotelSearchSubmit } from './HotelSearchSubmit';

export type HotelAutocompleteOption = {
    text: string;
    key?: string;
    hierarchy: string[];
    searchable: boolean;
    type: 'hotel' | 'place' | 'country' | 'theme';
    themePath?: string;
};

export type HotelSearchFormProps = {
    // biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
    dispatch: (action: any) => void;
    destination?: string;
    destinationContext?: HotelAutocompleteOption;
    startDate?: Date;
    endDate?: Date;
    rooms?: HotelRoom[];
    tracking?: string;
    widget?: string;
    position: string;
};

const {
    publicRuntimeConfig: { hotelAutocompleteUrl, hotelResultUrl },
} = getConfig();

export const HotelSearchForm = (props: HotelSearchFormProps) => {
    const [pristine, setPristine] = useState(true);

    const generateRoomInfoText = (): string => {
        const roomsText = t('hotel.room.amount', { count: props.rooms?.length });
        const adultsText = t('flight.passengers.adult', { count: props.rooms?.reduce((last, current) => last + current.adults, 0) });
        const childrenCount = props.rooms?.reduce((last, current) => last + current.children.length, 0);

        if (childrenCount && childrenCount > 0) {
            const childrenText = t('flight.passengers.child', { count: childrenCount });
            return t('hotel.room.infoAdultsChildren', { adultsText, childrenText, roomsText });
        }
        return t('hotel.room.infoAdults', { adultsText, roomsText });
    };

    const roomsAreValid = (): boolean => !props.rooms?.some((room) => room.children.length + room.adults === 0);
    const datesAreOk = (): boolean =>
        !!props.startDate && !!props.endDate && differenceInCalendarDays(props.startDate, props.endDate) <= 30;
    const datesAreClear = (): boolean => !props.startDate && !props.endDate;
    const datesAreValid = (): boolean => datesAreClear() || datesAreOk();
    const needsMoreInput = (): boolean => !datesAreValid() || !roomsAreValid() || !props.destination || !props.destinationContext;

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        setPristine(false);

        if (needsMoreInput()) {
            event.preventDefault();
        } else {
            const destination = props.destinationContext;
            const numberOfAdults = props.rooms?.reduce((sum, room) => sum + room.adults, 0);
            const numberOfChildren = props.rooms?.reduce((sum, room) => sum + room.children.length, 0);

            const item = {
                id: 'finntravel',
                type: 'Travel',
                destination: destination ? destination.hierarchy[0] : undefined,
                arrivalCity: destination ? destination.hierarchy[1] : undefined,
                carriers: [destination?.type === 'hotel' ? destination.key : undefined],
                travelType: 'hotel',
                departureDate: props.startDate,
                returnDate: props.endDate,
                numberOfAdults: numberOfAdults,
                numberOfChildren: numberOfChildren,
                numberOfRooms: props.rooms?.length,
            };
            trackSearchClick('Search hotel', '/reise/hotell', 'hotel', props.position, item);
            storeLastSearchHotel(props);
        }
    };

    const numberOfMonths = isMobile() ? 1 : 2;
    const invalid = {
        destination: !pristine && (!props.destination || !props.destinationContext),
        rooms: !pristine && !roomsAreValid(),
        startDate: !pristine && props.endDate && !props.startDate,
        endDate: !pristine && props.startDate && !props.endDate,
        dateRange: !pristine && props.startDate && props.endDate && differenceInCalendarDays(props.startDate, props.endDate) > 30,
    };

    return (
        <section className="bg-aqua-50 rounded-8 p-16" data-testid="hotelSearch" aria-label={t('hotel.title')}>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-16">
                <Autocomplete
                    id="destination"
                    data-testid="hotelAutocomplete"
                    label={t('hotel.destination.label')}
                    placeholder={t('hotel.destination.placeholder')}
                    defaultValue={props.destination}
                    onSelect={(destination) => props.dispatch(actions.setDestination(destination))}
                    url={hotelAutocompleteUrl}
                    error={invalid.destination ? t('hotel.validation.destination') : undefined}
                    mapOptions={hotelAutocompleteMapper}
                />
                <DateRangePicker
                    startDate={props.startDate}
                    endDate={props.endDate}
                    minimumNights={1}
                    onChange={(newDates) => props.dispatch(actions.setDates(newDates))}
                    isDayDisabled={isNotWithinOneYearOfToday}>
                    <div className="grid grid-cols-2 gap-x-16">
                        <DatePickerInput
                            as={TextField}
                            dateField="startDate"
                            data-testid="Hotel-dateRangeStart"
                            id="date-range-start"
                            label={t('hotel.checkin.label')}
                            aria-label={t('hotel.checkin.label')}
                            placeholder={t('hotel.checkin.placeholder')}
                            error={invalid.startDate}
                            helpText={invalid.startDate ? t('hotel.validation.dateRangeStart') : undefined}
                        />
                        <DatePickerInput
                            as={TextField}
                            dateField="endDate"
                            data-testid="Hotel-dateRangeEnd"
                            id="date-range-end"
                            label={t('hotel.checkout.label')}
                            aria-label={t('hotel.checkout.label')}
                            placeholder={t('hotel.checkout.placeholder')}
                            error={invalid.endDate || invalid.dateRange}
                            helpText={
                                invalid.endDate
                                    ? t('hotel.validation.dateRangeEnd')
                                    : invalid.dateRange
                                      ? t('hotel.validation.dateRangeGap')
                                      : undefined
                            }
                        />
                    </div>
                    <DatePickerPopover className="date-popover">
                        <DatePickerCalendar numberOfMonths={numberOfMonths} />
                    </DatePickerPopover>
                </DateRangePicker>
                <Dropdown id="hotelRooms" label={t('hotel.room.title')} text={generateRoomInfoText()}>
                    <div aria-live="polite" className="sr-only">
                        {t('hotel.room.amount', { count: props.rooms?.length })}
                    </div>
                    <HotelRooms rooms={props.rooms ?? []} dispatch={props.dispatch} />
                </Dropdown>
            </div>
            <HotelSearchSubmit {...props} handleSubmit={handleSubmit} resultUrl={hotelResultUrl} />
        </section>
    );
};
